import React, { useEffect, useState } from "react";
import SearchBar from "../../../../components/NewDesign/SearchBar/SearchBar";
import FilterBar from "../../../../components/NewDesign/FilterBar";
import SortBar from "../../../../components/NewDesign/SortBar";
import UserReportRow from "./UserReportRow";
import { useStore } from "../../../../store/useStore";
import Loading from "../../../../components/OldDesign/Loading";
import Pagination from "../../../../components/OldDesign/Pagination";

const UserReports = () => {
  const getCnaConnections = useStore((state) => state.getCnaConnections);
  const cnaConnections = useStore((state) => state.cnaConnections);
  // PAGINATION
  const [pageSize, setPageSize] = useState(6); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page
  useEffect(() => {
    const options = {
      page: currentPage,
      rows: pageSize,
    };
    getCnaConnections(options);
  }, [getCnaConnections, currentPage, pageSize]);
  if (!cnaConnections.isLoaded) {
    return null;
  }
  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex items-center gap-3">
        <SearchBar disabled />
        <SortBar disabled />
        <div className="ml-auto">
          <FilterBar />
        </div>
      </div>
      <div className="flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2 max-lg:hidden">
        <div className="basis-[30%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">İsim Soyisim</p>
        </div>
        <div className="basis-[30%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Pozisyon İsmi</p>
        </div>
        <div className="basis-[30%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Humanas Kimliği</p>
        </div>
        <div className="basis-[10%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
        </div>
      </div>
      <div className="flex flex-col gap-4 overflow-y-auto lg:max-h-[calc(100%-162px)]">
        {!cnaConnections.isLoaded ? null : cnaConnections.isLoading ? (
          <Loading />
        ) : (
          cnaConnections.data.rows.map((user, index) => {
            return <UserReportRow user={user} key={index} />;
          })
        )}
      </div>
      <div className="mt-auto">
        <Pagination
          dataLength={cnaConnections.data.rows.length}
          setPageSize={setPageSize}
          currentPage={currentPage}
          totalCount={cnaConnections.data.rows.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default UserReports;
