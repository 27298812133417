import React, { useEffect, useState } from "react";
import ExpBar from "../../../../components/NewDesign/StatusBar/ExpBar";
import { PeopleFilled, PlusOutlined } from "../../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../components/OldDesign/Loading";
import Button from "../../../../components/NewDesign/Button/Button";
import { useStore } from "../../../../store/useStore";
import Pagination from "../../../../components/OldDesign/Pagination";

const JobPositionRow = ({ position }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const openAnalysisModal = (id) => {
    navigate(`/jobposition/${id}/home`, {
      state: { previousLocation: location },
    });
  };
  return (
    <div className="flex flex-wrap items-center justify-between rounded-lg border border-neutral-100 bg-secondary_recruiter-100 px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] max-lg:gap-3">
      <p className="basis-[22%] font-medium leading-tight text-primary-800 max-lg:basis-1/3">{position.name}</p>
      <div className="flex basis-[10%] items-center gap-0.5 max-lg:basis-1/3 max-lg:justify-end">
        <PeopleFilled className="h-3 w-3 text-primary-900" />
        <p className="text-[13px] font-medium leading-tight text-primary-900">
          {position.seat_metacv_count}/{position.seat_count}
        </p>
      </div>
      <div className="flex basis-[15%] flex-col max-lg:basis-full">
        <span className="text-[13px] font-bold leading-tight text-chart-purple2">
          {position.progress_percent_total} %
        </span>
        <ExpBar className="h-2" percent={position.progress_percent_total} />
      </div>
      <div className="flex basis-[15%]">
        {position.state === "completed" ? (
          <Button
            onClick={() => navigate(`/cna/my-positions/${position.id}`)}
            className="flex-1 border-secondary_applicant-700 bg-secondary_applicant-100 px-6 py-2 text-secondary_applicant-700"
          >
            Görüntüle
          </Button>
        ) : (
          <Button
            onClick={() => openAnalysisModal(position.id)}
            className="flex-1 bg-primary-50 px-6 py-2 text-primary-600"
          >
            Devam Et
          </Button>
        )}
      </div>
    </div>
  );
};

const MyJobPositions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getJobPositionList = useStore((state) => state.getJobPositionList);
  const jobPositions = useStore((state) => state.jobPositions);

  // PAGINATION
  const [pageSize, setPageSize] = useState(6); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page

  useEffect(() => {
    const options = {
      page: currentPage,
      rows: pageSize,
    };
    getJobPositionList(options);
  }, [getJobPositionList, currentPage, pageSize]);

  if (!jobPositions.isLoaded) {
    return null;
  }
  // console.log(jobPositions);
  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between pb-4">
        {/* <h2 className="text-[20px] font-bold leading-tight text-primary-900 max-lg:text-base">İş Pozisyonlarım</h2> */}
        <Button
          onClick={() => {
            navigate(`/add-position`, {
              state: { previousLocation: location, options: { rows: pageSize, page: currentPage } },
            });
          }}
          className={"px-6 py-2 max-lg:px-6 max-lg:py-2"}
          icon2={<PlusOutlined className={"h-4 w-4"} />}
        >
          Yeni Ekle
        </Button>
        <Button
          // onClick={() => {
          //   navigate(`/add-position`, {
          //     state: { previousLocation: location },
          //   });
          // }}
          className={"hidden px-6 py-2 max-lg:px-6 max-lg:py-2"}
          // icon2={<PlusOutlined className={"h-4 w-4"} />}
        >
          Rapor Talep Et
        </Button>
      </div>
      <div className="mb-4 flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2 max-lg:hidden">
        <div className="basis-[22%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Pozisyon İsmi</p>
        </div>
        <div className="basis-[10%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Kişi Sayısı</p>
        </div>
        <div className="basis-[15%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">İlerleme</p>
        </div>
        <div className="basis-[15%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
        </div>
      </div>
      <div className="-mx-2 -my-1 flex flex-col gap-3 overflow-y-auto px-3 py-1 lg:max-h-[calc(100%-142px)]">
        {!jobPositions.isLoading ? (
          <>
            {jobPositions.data.rows.map((position, index) => (
              <JobPositionRow key={index} position={position} />
            ))}
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="mt-auto">
        <Pagination
          dataLength={jobPositions.data.pagination_info.total_row_count}
          setPageSize={setPageSize}
          currentPage={currentPage}
          totalCount={jobPositions.data.pagination_info.total_row_count}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      {/* <GradientBorder className="h-full bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] max-lg:col-span-1 max-lg:row-span-2 max-lg:p-3 lg:col-span-4 lg:row-span-3">
      </GradientBorder> */}

      {/* <GradientBorder className="col-span-1 bg-primary-800 p-6 max-lg:hidden lg:row-span-2">
        <div className="flex flex-col items-center">
          <h2 className="text-[20px] font-bold leading-tight text-neutral-100">Yeni Pozisyon Ekle</h2>
          <p className="py-6 text-center font-medium leading-tight text-primary-200">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras laoreet dui eu gravida ultricies. Phasellus
            tincidunt urna maximus, rutrum neque id, eleifend ligula.
          </p>
          <Button
            onClick={() => {
              navigate(`/add-position`, {
                state: { previousLocation: location },
              });
            }}
            className="border-neutral-100 bg-neutral-100 p-3 text-base text-primary-900"
            icon={<RightArrowOutlined className={"h-4 w-4"} />}
          >
            Yeni Pozisyon Ekle
          </Button>
        </div>
      </GradientBorder> */}
    </div>
  );
};

export default MyJobPositions;
