import React, { useEffect, useState } from "react";
import GradientBorder from "../../../components/NewDesign/GradientBorder";
import { ChevronDownOutlined, PeopleFilled } from "../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/OldDesign/Loading";
import { useStore } from "../../../store/useStore";
import cnaRoadMap from "../../../assets/cna-roadmap.png";
import Button from "../../../components/NewDesign/Button/Button";
import CNAFirstAnalysis from "./CNAFirstAnalysis";
import { TagWithIcon } from "../../../components/NewDesign/Tags";

const PositionsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [quickFilter, setQuickFilter] = useState("");

  const handleChangeQuickFilter = (filter) => {
    if (quickFilter === "") {
      setQuickFilter(filter.value);
    } else if (quickFilter !== filter.value) {
      setQuickFilter(filter.value);
    } else setQuickFilter("");
  };

  const openReportModal = (id) => {
    navigate(`/analysis/${id}`, {
      state: { previousLocation: location },
    });
  };
  const openAnalysisModal = (id) => {
    navigate(`/jobposition/${id}/home`, {
      state: { previousLocation: location },
    });
  };

  const quickFilters = [
    { value: "completed", label: "Tamamlanan" },
    { value: "core_cv", label: "Core Cv" },
    { value: "identity", label: "Turnuva" },
  ];

  const getJobPositionList = useStore((state) => state.getJobPositionList);
  const jobPositions = useStore((state) => state.jobPositions);

  const [pageSize, setPageSize] = useState(10);
  const hideLoadMore =
    jobPositions.data.pagination_info?.current_row_count > jobPositions.data.pagination_info?.total_row_count;
  const loadCount = 10;
  const handleLoadMorePosition = () => {
    if (jobPositions.data.pagination_info.current_row_count < jobPositions.data.pagination_info.total_row_count) {
      setPageSize((prev) => prev + loadCount);
    }
  };

  useEffect(() => {
    const options = {
      rows: pageSize,
      page: 1,
    };
    getJobPositionList(options);
  }, [getJobPositionList, pageSize]);

  return (
    <div className="flex h-full flex-col gap-3">
      <div className="flex items-center justify-between">
        <h2 className="text-[20px] font-bold leading-tight text-primary-900 max-lg:text-lg">
          İş Pozisyonu Uygunluk Analizi
        </h2>
        <span className="rounded-full bg-primary-200 px-2 py-1 text-sm font-semibold leading-tight text-primary-600">
          {jobPositions.data.rows?.filter((job) => job.state === "completed").length}/{jobPositions.data.rows?.length}
        </span>
      </div>
      <div className="flex gap-1.5">
        {quickFilters.map((filter, index) => {
          return (
            <TagWithIcon
              className={`${
                quickFilter === filter.value ? "bg-secondary_recruiter-600" : "bg-neutral-100 text-primary-800"
              }`}
              key={index}
              onClick={() => handleChangeQuickFilter(filter)}
              text={filter.label}
            />
          );
        })}
      </div>
      <div className="-mx-3 -my-1 flex h-0 min-h-[calc(100%-78px)] flex-col gap-2 overflow-y-auto px-3 py-1 max-lg:min-h-[calc(100vh-300px)]">
        {jobPositions.isLoaded ? (
          jobPositions.data.rows
            .filter((position) => position.state.includes(quickFilter))
            .map((position, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between rounded-lg bg-secondary_recruiter-100  px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] transition-all hover:bg-secondary_recruiter-200"
                >
                  <div className="flex flex-col gap-1">
                    <p className="font-medium leading-tight text-primary-800">{position.name}</p>
                    <span className="flex items-center gap-0.5 text-[13px] font-medium leading-tight text-primary-800">
                      <PeopleFilled className="h-3 w-3" />
                      {position.seat_metacv_count}/{position.seat_count}
                    </span>
                  </div>
                  {position.state === "completed" ? (
                    <Button
                      onClick={() => openReportModal(position.id)}
                      className="border-secondary_applicant-700 bg-secondary_applicant-100 px-6 py-2 text-secondary_applicant-700"
                    >
                      Görüntüle
                    </Button>
                  ) : (
                    <Button
                      onClick={() => openAnalysisModal(position.id)}
                      className="bg-primary-50 px-6 py-2 text-primary-600"
                    >
                      Devam Et
                    </Button>
                  )}
                </div>
              );
            })
        ) : jobPositions.isLoading ? (
          <Loading />
        ) : null}
        <Button
          onClick={handleLoadMorePosition}
          className={`${hideLoadMore && "hidden"} border-none bg-transparent text-neutral-800`}
          icon={<ChevronDownOutlined className={"h-4 w-4"} />}
          loading={jobPositions.isLoading}
        >
          Load More
        </Button>
      </div>
    </div>
  );
};

const CNAMainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="grid h-full grid-cols-2 gap-6 max-lg:grid max-lg:auto-rows-auto max-lg:grid-cols-1 max-lg:gap-2.5">
      <div className="flex flex-col gap-6 max-lg:order-2 max-lg:gap-2.5">
        {/* ROAD MAP */}
        <div className="relative max-lg:hidden">
          <h2 className="absolute left-6 top-6 text-[20px] font-bold leading-tight text-neutral-1000">
            İhtiyaç Yol Haritası
          </h2>
          <img src={cnaRoadMap} alt="roadmap" className="" />
        </div>
        {/* COMPANY QUESTIONS */}
        <GradientBorder className="flex-1 bg-neutral-100 bg-opacity-50 p-6 max-lg:p-4 lg:min-h-[330px] lg:backdrop-blur-md">
          <CNAFirstAnalysis />
        </GradientBorder>
      </div>
      {/* POSITION ANALYSES */}
      <GradientBorder className="bg-neutral-100 bg-opacity-50 p-6 max-lg:order-1 max-lg:p-4 lg:backdrop-blur-md">
        <PositionsList />
      </GradientBorder>
    </div>
  );
};

export default CNAMainPage;
