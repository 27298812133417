import React, { forwardRef, Fragment } from "react";
import { TagWithIcon } from "../Tags";
import {
  BagIconFilled,
  BirthdayFilled,
  LocationFilled,
  MailFilled,
  PhoneFilled,
  SendFilled,
} from "../../OldDesign/Icons";
import { Link } from "react-router-dom";
import Avatar from "../Avatars/Avatar";
import Button from "../Button/Button";
import { useStore } from "../../../store/useStore";
import Tooltip from "../Tooltips/Tooltip";
import { Wordcloud } from "@visx/wordcloud";
import OrientationSummary from "../OrientationSummary";
import useIsMobile from "../../../utils/useIsMobile";

const CvHeader = forwardRef((props, ref) => {
  const { user, showTopBar } = props;
  const work_locations = [];
  for (const [key, value] of Object.entries(user.work_location)) {
    if (value) {
      work_locations.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  const work_times = [];
  for (const [key, value] of Object.entries(user.work_time)) {
    if (value) {
      if (key === "fulltime") work_times.push("Full Time");
      if (key === "parttime") work_times.push("Part Time");
      if (key === "freelance") work_times.push("Freelance");
    }
  }
  return (
    <div id="header" ref={ref} className={`grid grid-cols-2 gap-4 pt-6 max-lg:grid-cols-1 max-lg:pt-2`}>
      <CoreCvBox user={user} />
      <AIDBox user={user} />
      <PersonalityBox user={user} />
      <OrientationBox user={user} />
    </div>
  );
});

const CoreCvBox = ({ user }) => {
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:hidden max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Core CV</h4>
        <Button className={"border-none bg-transparent p-0 text-primary-600 max-lg:p-0"}>Detayları gör</Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <Avatar user={user} />
          <div className="inline-flex flex-1 flex-col gap-1">
            <p className="text-4xl font-bold leading-tight text-primary-900">{user.name}</p>
            <p className="text-xl font-medium leading-tight text-neutral-700">{user.job.job_name}</p>
          </div>
        </div>
        <ul className="flex flex-wrap">
          <li className="basis-2/3 pb-2 text-sm leading-tight text-primary-900">
            <MailFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">{user.email}</span>
          </li>
          <li className="basis-1/3 pb-2 text-sm leading-tight text-primary-900">
            <LocationFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">
              {user.location_state.state_name || "Not found"}, {user.location_country.country_name || "Not found"}
            </span>
          </li>
          <li className="basis-2/3 text-sm leading-tight text-primary-900">
            <PhoneFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">{user.phone || "Not found"}</span>
          </li>
          <li className="basis-1/3 text-sm leading-tight text-primary-900">
            <BirthdayFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
            <span className="pl-1">{user.birth_date || "Not found"}</span>
          </li>
        </ul>
      </div>
      <hr className="mt-auto"></hr>
      <div className="flex gap-3">
        <TagWithIcon
          icon={<SendFilled className="h-[14px] w-[14px]" />}
          className="bg-secondary_recruiter-700  text-secondary_recruiter-100"
          text={`${user.is_employee ? "Çalışan" : "Aday"}`}
        />
        <TagWithIcon
          icon={<BagIconFilled className="h-[14px] w-[14px]" />}
          className={`${
            user.work_state === "unemployed" ? "bg-success-400 text-success-100" : " bg-error-300 text-error-100"
          }`}
          text={`${user.work_state === "unemployed" ? "Çalışmıyor" : "Çalışıyor"}`}
        />
      </div>
    </div>
  );
};
const AIDBox = ({ user }) => {
  const strapiContent = useStore((state) => state.strapiContent);
  const _aid = strapiContent.data.find((aid) => aid.attributes.codeName === user.metacv.aid[0].aid);
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Humanas Kimliği</h4>
        <Button className={"border-none bg-transparent p-0 text-primary-600 max-lg:p-0"}>Detayları gör</Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <Link to={`/aid/identities/${user.metacv.aid[0].aid}`}>
            <div
              style={{
                background: `linear-gradient(180deg, ${_aid.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
              }}
              className={`h-[100px] w-[100px] rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-[80px] max-lg:w-[80px]`}
            >
              <img
                src={process.env.REACT_APP_STRAPI_BASE_URL + user.metacv.aid[0].logo}
                alt={user.metacv.aid[0].name}
              />
            </div>
          </Link>
          <div className="inline-flex flex-1 flex-col gap-1">
            <p className="text-lg font-bold leading-tight text-primary-900">{_aid.attributes.name}</p>
            <p className="text-sm font-medium leading-tight text-neutral-900">{_aid.attributes.title}</p>
          </div>
        </div>
        <p className="text-sm leading-tight text-primary-900">{_aid.attributes.definition}</p>
      </div>
      <hr className="mt-auto"></hr>
      <div className="scrollbar-hide flex gap-3 overflow-x-auto max-lg:gap-1.5">
        {_aid.attributes.keyword.map((keyword, index) => (
          <TagWithIcon className={"bg-primary-75 text-primary-800"} key={index} text={keyword.keyword} />
        ))}
      </div>
    </div>
  );
};
const PersonalityBox = ({ user }) => {
  const isMobile = useIsMobile();
  function showTooltip(evt, text) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left = evt.clientX > 150 ? evt.clientX - 130 + "px" : evt.clientX - 20 + "px";
    tooltip.style.top = evt.clientY + 10 + "px";
  }

  function hideTooltip() {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  }
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Kişilik Tipi</h4>
        <Button className={"border-none bg-transparent p-0 text-primary-600 max-lg:p-0"}>Detayları gör</Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="mx-auto">
          <div
            id="tooltip"
            display="none"
            className="fixed z-[999] hidden max-w-[360px] rounded-lg bg-primary-800 p-2 text-justify text-sm text-neutral-100"
          ></div>
          <Wordcloud
            words={user.metacv.word_cloud}
            fontSize={(word) => Math.sqrt(word.value)}
            padding={8}
            font={"Inter"}
            rotate={(word) => 0}
            spiral="archimedean"
            width={isMobile ? 325 : 527}
            height={isMobile ? 260 : 200}
            random={() => 0.5}
          >
            {(cloudWords) =>
              cloudWords.map((word, index) => {
                return (
                  <Fragment key={index}>
                    <defs key={index}>
                      <filter x="-4%" y="-5%" width="108%" height="110%" id={index}>
                        <feFlood floodColor={word.bgColor} />
                        <feGaussianBlur stdDeviation="2" />
                        <feComponentTransfer>
                          <feFuncA type="table" tableValues="0 0 0 1" />
                        </feComponentTransfer>

                        <feComponentTransfer>
                          <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                        </feComponentTransfer>
                        <feComposite operator="over" in="SourceGraphic" />
                      </filter>
                    </defs>
                    <text
                      style={{
                        fontSize: word.size,
                        fontWeight: Math.log(word.value) * 100,
                        fill: word.color,
                        fontFamily: word.font,
                        cursor: "help",
                      }}
                      filter={`url(#${index})`}
                      textAnchor="middle"
                      transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                      onMouseMove={(e) => showTooltip(e, word.explain.description)}
                      onMouseLeave={hideTooltip}
                    >
                      {word.text}
                    </text>
                  </Fragment>
                );
              })
            }
          </Wordcloud>
        </div>
      </div>
      <hr className="mt-auto"></hr>
      <div className="flex flex-wrap justify-center gap-3">
        {user.metacv.upper_dimensions.map((upType, index) => {
          return (
            <Tooltip key={index} content={upType.description}>
              <div className="flex cursor-help items-center justify-center gap-1">
                <span className={`text-sm max-lg:text-[8px]`} style={{ color: upType.color }}>
                  {"\u2B24"}
                </span>
                <p className="text-sm">{upType.name}</p>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};
const OrientationBox = ({ user }) => {
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 p-6 max-lg:gap-2 max-lg:p-4">
      <div className="flex items-baseline justify-between">
        <h4 className="text-lg font-semibold text-primary-900">Mesleki Yönelim</h4>
        <Button className={"border-none bg-transparent p-0 text-primary-600 max-lg:p-0"}>Detayları gör</Button>
      </div>
      <div className="flex flex-1 flex-col items-center gap-4">
        <OrientationSummary summary={user.metacv.dimensions_stats} />
      </div>
    </div>
  );
};

export default CvHeader;
